import * as api from "@/api";
import Analytics from "@/components/Analytics";
import BlockedPage from "@/components/BlockedPage";
import CaptchaIframe from "@/components/CaptchaIframe";
import CommonShell from "@/components/CommonShell";
import LoadingScreen from "@/components/LoadingScreen";
import LoginPage from "@/components/LoginPage";
import MessageDialog from "@/components/MessageDialog";
import OrderHistoryPage from "@/components/OrderHistoryPage";
import PremiumPage from "@/components/PremiumPage";
import ProfilePage from "@/components/ProfilePage";
import Prompt from "@/components/Prompt";
import SecurityPage from "@/components/SecurityPage";
import { loadDependencies } from "@/dependencies";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	setIsCaptchaShown,
	setIsLoadingScreenShown,
	setLogin,
	setRegion,
} from "@/redux/reducers/app";
import { LoginInfo, LoginTab } from "@/types";
import {
	changePhone,
	deleteAccount,
	loadJs,
	logOut,
	showDialog,
} from "@/utils";
import { useQueryClient } from "@tanstack/react-query";
import { JSX, useCallback, useEffect } from "react";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";

function App(): JSX.Element {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const isCaptchaShown = useAppSelector((state) => state.app.isCaptchaShown);
	const isLoadingScreenShown = useAppSelector(
		(state) => state.app.isLoadingScreenShown,
	);

	const isBlockedPage = location.pathname === "/blocked";
	const isLoginPage = location.pathname === "/login";

	const prefetch = useCallback((): void => {
		const handleForbiddenError = (): void => {
			void logOut(dispatch);
		};
		void queryClient.prefetchQuery({
			queryFn: () => api.getAllDevices(handleForbiddenError),
			queryKey: ["devices", globals.login.username],
			retry: false,
		});
		void queryClient.prefetchQuery({
			queryFn: () => api.getExpirationTime(handleForbiddenError),
			queryKey: ["expirationTime", globals.login.username],
			retry: false,
		});
		void queryClient.prefetchQuery({
			queryFn: () => api.getPremiumInfo(handleForbiddenError),
			queryKey: ["premiumInfo", globals.login.username],
			retry: false,
		});
		void queryClient.prefetchQuery({
			queryFn: () =>
				api.getProfile(["realId", "regTime"], handleForbiddenError),
			queryKey: ["profile", globals.login.username],
			retry: false,
		});
		void queryClient.prefetchQuery({
			queryFn: api.getOrders,
			queryKey: ["orders", globals.login.username],
			retry: false,
		});
	}, [dispatch, queryClient]);

	const loggedIn = useCallback((): void => {
		if (!globals.login) {
			return;
		}
		dispatch(setLogin(globals.login));
		const actionParam = globals.params.action;
		switch (actionParam) {
			case "changePhone": {
				void changePhone(dispatch);
				break;
			}
			case "deleteAccount": {
				void deleteAccount(dispatch);
				break;
			}
			default:
				break;
		}
		prefetch();
	}, [dispatch, prefetch]);

	const getInit = useCallback(async (): Promise<void> => {
		try {
			const data = await api.getInit();
			dispatch(setIsLoadingScreenShown(false));
			globals.dynamicInfo.loaded = true;
			if (data.region) {
				globals.dynamicInfo.region = data.region;
			}
			if (
				process.env.NODE_ENV === "development" ||
				globals.isIosApp ||
				!globals.dynamicInfo.region ||
				globals.dynamicInfo.region === "CN"
			) {
				void loadJs("https://turing.captcha.qcloud.com/TCaptcha.js");
			}
			if (data.login) {
				try {
					globals.login = JSON.parse(data.login) as LoginInfo;
					loggedIn();
				} catch (error) {
					console.error(error);
				}
			} else {
				dispatch(setIsLoadingScreenShown(false));
				if (!isBlockedPage && !isLoginPage) {
					void navigate("/login", {
						replace: true,
					});
				}
			}
			if (data.blockReason) {
				dispatch(setIsLoadingScreenShown(false));
				if (!isBlockedPage) {
					void navigate("/blocked", {
						replace: true,
						state: {
							reason: data.blockReason,
						},
					});
				}
				return;
			}
			if (data.redirectTo) {
				window.location.href = data.redirectTo;
				return;
			}
			if (data.region) {
				dispatch(setRegion(data.region));
			}
			if (data.alert) {
				void showDialog(dispatch, data.alert);
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	}, [dispatch, isBlockedPage, isLoginPage, loggedIn, navigate]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.ctrlKey || event.metaKey) {
				switch (event.code) {
					case "KeyI":
					case "KeyU": {
						if (process.env.NODE_ENV === "production") {
							event.preventDefault();
						}
						break;
					}
					default:
						break;
				}
			} else if (
				event.key === "F12" &&
				process.env.NODE_ENV === "production"
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return (): void => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		const handleMessage = (event: MessageEvent): void => {
			try {
				if (typeof event.data !== "string") {
					return;
				}
				const data = JSON.parse(
					decodeURIComponent(window.atob(event.data)),
				) as {
					action?: string;
					validate?: string;
				};
				if (data.action === "captcha") {
					dispatch(setIsCaptchaShown(false));
					if (globals.captchaCallback) {
						globals.captchaCallback({
							ticket: data.validate,
						});
						globals.captchaCallback = null;
					}
				}
			} catch (error) {
				if (process.env.NODE_ENV === "development") {
					console.warn(event.data, error);
				}
			}
		};
		window.addEventListener("message", handleMessage);
		return (): void => {
			window.removeEventListener("message", handleMessage);
		};
	}, [dispatch]);

	useEffect(() => {
		const handleLocation = (): boolean => {
			if (
				process.env.NODE_ENV === "production" &&
				window.location.hostname !== "account.retiehe.com"
			) {
				window.location.href = "https://account.retiehe.com/";
				return false;
			} else if (window.location.search) {
				window.history.replaceState(null, "", window.location.pathname);
			}
			return true;
		};

		if (globals.dynamicInfo.loaded || !handleLocation()) {
			return;
		}
		void getInit();
	}, [dispatch, getInit, isBlockedPage, isLoginPage, loggedIn, navigate]);

	useEffect(loadDependencies, []);

	return (
		<>
			<Routes>
				<Route
					index
					element={
						<CommonShell>
							<ProfilePage />
						</CommonShell>
					}
				/>
				<Route
					path="/blocked"
					element={<BlockedPage loggedIn={loggedIn} />}
				/>
				<Route
					path="/login"
					element={<LoginPage loggedIn={loggedIn} />}
				/>
				<Route
					path="/login.html"
					element={<Navigate to="/login" />}
				/>
				<Route
					path="/login-airportal.html"
					element={<Navigate to="/login" />}
				/>
				<Route
					path="/orders"
					element={
						<CommonShell>
							<OrderHistoryPage />
						</CommonShell>
					}
				/>
				<Route
					path="/premium"
					element={
						<CommonShell>
							<PremiumPage />
						</CommonShell>
					}
				/>
				<Route
					path="/security"
					element={
						<CommonShell>
							<SecurityPage />
						</CommonShell>
					}
				/>
				<Route
					path="/.well-known/change-password"
					element={
						<LoginPage
							initialTab={LoginTab.RESET_PASSWORD}
							loggedIn={loggedIn}
						/>
					}
				/>
				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Routes>
			{isCaptchaShown && <CaptchaIframe />}
			{isLoadingScreenShown && <LoadingScreen />}
			<MessageDialog />
			<Prompt />
			<Analytics />
		</>
	);
}

export default App;
