import { JSX, useEffect } from "react";

function Analytics(): JSX.Element {
	useEffect(() => {
		window.setTimeout(() => {
			const stat = document.createElement("script");
			stat.async = true;
			stat.src =
				"https://hm.baidu.com/hm.js?18f6ecef5f0d46dfd1259a61fac601b4";
			document.body.appendChild(stat);
		}, 1000);
	}, []);

	return <></>;
}

export default Analytics;
