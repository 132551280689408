import styles from "@/styles/LoadingScreen.module.css";
import { JSX } from "react";

function LoadingScreen(): JSX.Element {
	return (
		<div className="overlay blur">
			<div
				className={styles["loader"]}
				data-testid="loading"
			></div>
		</div>
	);
}

export default LoadingScreen;
