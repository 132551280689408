import * as api from "@/api";
import LoginFooter from "@/components/LoginFooter";
import PasswordChecker from "@/components/PasswordChecker";
import Tab from "@/components/Tab";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import styles from "@/styles/LoginPage.module.css";
import {
	BlockedTab,
	DialogInfo,
	LoginInfo,
	LoginResponse,
	LoginTab,
	PasswordChecklist,
	ReduxDispatch,
	WindowExt,
} from "@/types";
import {
	showDialog as _showDialog,
	encodeData,
	formatEmail,
	formatPhoneNumber,
	handleKeyboardClick,
	showCaptcha,
	sleep,
} from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import {
	ChangeEvent,
	FormEvent,
	JSX,
	KeyboardEvent,
	MouseEvent,
	useRef,
	useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface LoginLocationState {
	emailOrPhone?: string;
}

interface LoginPageProps {
	initialTab?: LoginTab;
	loggedIn: () => void;
}

enum DigitType {
	UNKNOWN,
	NUMBER,
	LETTER,
	OTHER,
}

enum SignUpMethod {
	ALL,
	EMAIL,
	PHONE,
}

function LoginPage({
	initialTab = LoginTab.LOGIN,
	loggedIn,
}: LoginPageProps): JSX.Element {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const region = useAppSelector((state) => state.app.region);

	const appNameParam = globals.params.appname?.toLowerCase();
	const isIframe = window.self !== window.top;
	const hasLoginCallback = isIframe || globals.params.page === "login";
	const locationStates = location.state as LoginLocationState | undefined;
	const windowExt: WindowExt = window;

	const [emailOrPhone, setEmailOrPhone] = useState<string>(
		locationStates?.emailOrPhone || "",
	);
	const [code, setCode] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [isPasswordRequirementsShown, setIsPasswordRequirementsShown] =
		useState<boolean>(false);
	const [password, setPassword] = useState<string>("");
	const [passwordChecklist, setPasswordChecklist] =
		useState<PasswordChecklist>({
			hasLowercase: false,
			hasNoCommonCombinations: false,
			hasNoConsecutiveNumbers: false,
			hasUppercase: false,
			isLongEnough: false,
		});
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [tab, setTab] = useState<LoginTab>(initialTab);
	const [seconds, setSeconds] = useState<number>(0);

	const codeInput = useRef<HTMLInputElement>(null);
	const confirmPasswordInput = useRef<HTMLInputElement>(null);
	const emailOrPhoneInput = useRef<HTMLInputElement>(null);
	const incorrectPasswordCount = useRef<number>(0);
	const passwordInput = useRef<HTMLInputElement>(null);
	const remainingSeconds = useRef<number>(60);

	const signUpMethod = ((): SignUpMethod => {
		if (region && region !== "CN") {
			return SignUpMethod.EMAIL;
		} else if (appNameParam === "airportal") {
			return SignUpMethod.PHONE;
		} else {
			return SignUpMethod.ALL;
		}
	})();

	const defaultLoginCallback = (data: LoginResponse): void => {
		globals.login = data;
		loggedIn();
		void navigate("/", {
			replace: true,
		});
	};

	const formatEmailOrPhone = (value: string): string => {
		if (value.includes("@")) {
			return value
				.toLowerCase()
				.replace(/\s/g, "")
				.replace(/\.con$/, ".com");
		} else {
			return formatPhoneNumber(value);
		}
	};

	const goToPage = (pageName: string): void => {
		switch (pageName) {
			case "realIdRequired": {
				void navigate("/blocked", {
					replace: true,
					state: {
						initialTab: BlockedTab.REAL_ID_REQUIRED,
					},
				});
				break;
			}
			case "resetPassword": {
				setTab(LoginTab.RESET_PASSWORD);
				break;
			}
			case "setPhone": {
				setTab(LoginTab.SET_PHONE);
				break;
			}
			case "signUp": {
				setTab(LoginTab.SIGN_UP);
				break;
			}
			case "sms": {
				setTab(LoginTab.TFA_SMS);
				break;
			}
			case "totp": {
				setTab(LoginTab.TFA_TOTP);
				break;
			}
			default: {
				setTab(LoginTab.LOGIN);
				break;
			}
		}
	};

	const handleEmailOrPhoneKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (emailOrPhone && tab === LoginTab.LOGIN) {
				passwordInput.current?.focus();
			}
		}
	};

	const handleInputChange = (callback: (newValue: string) => void) => {
		return (event: ChangeEvent<HTMLInputElement>): void => {
			callback(event.target.value);
		};
	};

	const handlePasswordChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		setPassword(event.target.value);
		isPasswordSecure(event.target.value, false);
	};

	const handleLogoClick = (): void => {
		window.open(globals.home);
	};

	const handlePasswordBlur = (): void => {
		setIsPasswordRequirementsShown(false);
	};

	const handlePasswordFocus = (): void => {
		setIsPasswordRequirementsShown(true);
	};

	const handlePasswordKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (password) {
				if (
					tab === LoginTab.SIGN_UP ||
					tab === LoginTab.RESET_PASSWORD
				) {
					confirmPasswordInput.current?.focus();
				} else {
					handleSubmitClick(event);
				}
			}
		}
	};

	const handlePhoneKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	const handleSendCodeClick = (): void => {
		void sendCode();
	};

	const handleSubmitClick = (
		event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>,
	): void => {
		event.preventDefault();
		void submitLogin();
	};

	const isPasswordSecure = (
		password: string,
		toShowDialog: boolean,
	): boolean => {
		if (!password) {
			setPasswordChecklist({
				hasLowercase: false,
				hasNoCommonCombinations: false,
				hasNoConsecutiveNumbers: false,
				hasUppercase: false,
				isLongEnough: false,
			});
			return false;
		}

		const newPasswordChecklist: PasswordChecklist = {
			hasLowercase: /[a-z]/.test(password),
			hasNoCommonCombinations: true,
			hasNoConsecutiveNumbers: true,
			hasUppercase: /[A-Z]/.test(password),
			isLongEnough: password.length >= 8,
		};

		if (
			toShowDialog &&
			(!newPasswordChecklist.hasLowercase ||
				!newPasswordChecklist.hasUppercase ||
				!newPasswordChecklist.isLongEnough)
		) {
			void showDialog(dispatch, t("passwordTip"));
			return false;
		}

		const passwordSplit = password.split("");
		const numParts: string[] = [];
		const wordParts: string[] = [];

		let lastDigitType = DigitType.UNKNOWN;
		let thisNum = "";
		let thisWord = "";

		const pushPart = (): void => {
			switch (lastDigitType) {
				case DigitType.NUMBER: {
					numParts.push(thisNum);
					thisNum = "";
					break;
				}
				case DigitType.LETTER: {
					wordParts.push(thisWord);
					thisWord = "";
					break;
				}
				default:
					break;
			}
		};

		for (const thisDigit of passwordSplit) {
			const thisDigitType = ((): DigitType => {
				if (!isNaN(parseInt(thisDigit)) && thisDigit !== " ") {
					return DigitType.NUMBER;
				} else if (/[a-z]/i.test(thisDigit)) {
					return DigitType.LETTER;
				} else {
					return DigitType.OTHER;
				}
			})();
			if (
				lastDigitType > DigitType.UNKNOWN &&
				thisDigitType !== lastDigitType
			) {
				pushPart();
			}
			switch (thisDigitType) {
				case DigitType.NUMBER: {
					thisNum += thisDigit;
					break;
				}
				case DigitType.LETTER: {
					thisWord += thisDigit;
					break;
				}
				default:
					break;
			}
			lastDigitType = thisDigitType;
		}
		pushPart();

		// checks for common words
		const commonWords = [
			"abc",
			"asdf",
			"minecraft",
			"password",
			"qwerty",
			"retiehe",
			"zxc",
		];
		for (let i = 0; i < wordParts.length; i++) {
			thisWord = wordParts[i];
			if (commonWords.includes(thisWord.toLowerCase())) {
				newPasswordChecklist.hasNoCommonCombinations = false;
				break;
			}
		}
		if (toShowDialog && !newPasswordChecklist.hasNoCommonCombinations) {
			void showDialog(
				dispatch,
				t("thisPasswordInsecure") +
					t("passwordEasyToGuess", {
						word: thisWord,
					}),
			);
			return false;
		}

		// checks for consecutive numbers
		for (let i = 0; i < numParts.length; i++) {
			const thisNum = numParts[i];
			const thisNumSplit = thisNum.split("");
			let consecutiveCount = 0;
			let lastDigit: number | undefined = undefined;
			for (let j = 0; j < thisNumSplit.length; j++) {
				const thisDigit = parseInt(thisNumSplit[j]);
				if (lastDigit) {
					if (
						(thisDigit === 0 && lastDigit === 9) ||
						Math.abs(thisDigit - lastDigit) === 1
					) {
						consecutiveCount++;
					} else {
						consecutiveCount = 0;
						break;
					}
				}
				lastDigit = thisDigit;
				newPasswordChecklist.hasNoConsecutiveNumbers =
					consecutiveCount < 3;
				if (!newPasswordChecklist.hasNoConsecutiveNumbers) {
					break;
				}
			}
			if (!newPasswordChecklist.hasNoConsecutiveNumbers) {
				break;
			}
		}
		if (toShowDialog && !newPasswordChecklist.hasNoConsecutiveNumbers) {
			void showDialog(
				dispatch,
				t("thisPasswordInsecure") +
					t("containsConsecutiveNumber", {
						num: thisNum,
					}),
			);
			return false;
		}

		setPasswordChecklist(newPasswordChecklist);
		return true;
	};

	const loginCallback = async (data: LoginResponse): Promise<void> => {
		delete data.success;
		if (globals.isAndroidApp) {
			windowExt.rth?.postMessage(JSON.stringify(data));
			return;
		}
		if (globals.isIosApp) {
			windowExt.webkit?.messageHandlers.rth.postMessage(
				JSON.stringify(data),
			);
			return;
		}
		if (hasLoginCallback) {
			data.action = "login";
			postMessage(data);
			if (!isIframe) {
				window.close();
				await sleep(500);
				let backendDomain: string | undefined = undefined;
				let frontendDomain: string | undefined = undefined;
				let postEncodedToken = "";
				if (appNameParam) {
					const preEncodedToken = [
						data.token,
						0,
						Math.round(Date.now() / 1000),
					].join(",");
					for (let i = 0; i < preEncodedToken.length; i++) {
						postEncodedToken += String.fromCharCode(
							preEncodedToken.charCodeAt(i) + 23,
						);
					}
					postEncodedToken = window
						.btoa(postEncodedToken)
						.replace(/=+$/, "");
					switch (appNameParam) {
						case "airportal": {
							backendDomain = "bird.airportal.cn";
							frontendDomain = "www.airportal.cn";
							break;
						}
						case "host": {
							backendDomain = "bird.retiehe.com";
							frontendDomain = "host.retiehe.com";
							break;
						}
						case "lime start page": {
							backendDomain = "bird.limestart.cn";
							frontendDomain = "limestart.cn";
							break;
						}
						default:
							break;
					}
				}
				if (frontendDomain) {
					window.location.href =
						"https://" +
						backendDomain +
						"/backend/sso?" +
						encodeData({
							callback: "https://" + frontendDomain + "/",
							token: postEncodedToken,
							username: data.username,
						});
				} else {
					defaultLoginCallback(data);
				}
			} else {
				defaultLoginCallback(data);
			}
		} else if (isIframe && window.top) {
			window.top.location.href = "./";
		} else {
			defaultLoginCallback(data);
		}
	};

	const postMessage = (data: unknown): boolean => {
		const caller = (window.opener || window.parent) as Window;
		if (!caller) {
			return false;
		}
		caller.postMessage(
			window.btoa(encodeURIComponent(JSON.stringify(data))),
			document.referrer ? new URL(document.referrer).origin : "*",
		);
		return true;
	};

	const preventDefault = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
	};

	const saveLogin = (data: LoginInfo): void => {
		if (!data.token) {
			return;
		}
		globals.login = {
			birthday: data.birthday,
			email: data.email,
			name: data.name,
			token: data.token,
			username: data.username,
		};
	};

	const sendCode = async (): Promise<void> => {
		switch (tab) {
			case LoginTab.SIGN_UP: {
				const formattedEmailOrPhone = formatEmailOrPhone(emailOrPhone);
				const isEmail = globals.emailPattern.test(
					formattedEmailOrPhone,
				);
				const isPhone = globals.phonePattern.test(
					formattedEmailOrPhone,
				);
				if (
					!formattedEmailOrPhone ||
					(isEmail && signUpMethod === SignUpMethod.PHONE) ||
					(isPhone && signUpMethod === SignUpMethod.EMAIL)
				) {
					if (signUpMethod === SignUpMethod.EMAIL) {
						void showDialog(
							dispatch,
							t("incorrectEmailAddressFormat"),
						);
						return;
					} else if (signUpMethod === SignUpMethod.PHONE) {
						void showDialog(
							dispatch,
							t("incorrectPhoneNumberFormat"),
						);
						return;
					} else {
						void showDialog(
							dispatch,
							t("incorrectEmailOrPhoneFormat"),
						);
						return;
					}
				}
				const captchaResponse = await showCaptcha(dispatch);
				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.signUp(
						formattedEmailOrPhone,
						captchaResponse,
					);
					if (data.alert) {
						void showDialog(dispatch, data.alert, {
							isHtml: true,
						});
					}
					if (!data.success) {
						return;
					}
					startCountdown();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			case LoginTab.RESET_PASSWORD: {
				const formattedEmailOrPhone = formatEmailOrPhone(emailOrPhone);
				if (!formattedEmailOrPhone) {
					void showDialog(dispatch, t("incorrectEmailOrPhoneFormat"));
					return;
				}
				const captchaResponse = await showCaptcha(dispatch);
				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.resetPassword(
						formattedEmailOrPhone,
						captchaResponse,
					);
					if (data.alert) {
						void showDialog(dispatch, data.alert);
					}
					if (!data.success) {
						return;
					}
					startCountdown();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			case LoginTab.SET_PHONE: {
				const formattedPhoneNum = formatPhoneNumber(phoneNumber);
				if (!formattedPhoneNum) {
					void showDialog(dispatch, t("incorrectPhoneNumberFormat"));
					return;
				}
				const captchaResponse = await showCaptcha(dispatch);
				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.setPhone({
						captchaResponse: captchaResponse,
						emailOrPhone: emailOrPhone,
						newPhone: formattedPhoneNum,
						password: password,
					});
					if (data.alert) {
						void showDialog(dispatch, data.alert);
					}
					if (!data.success) {
						return;
					}
					startCountdown();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			case LoginTab.TFA_SMS: {
				const captchaResponse = await showCaptcha(dispatch);
				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.sendSmsTfa(
						emailOrPhone,
						captchaResponse,
					);
					if (data.alert) {
						void showDialog(dispatch, data.alert);
					}
					if (!data.success) {
						return;
					}
					startCountdown();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			default:
				break;
		}
	};

	const showDialog = async (
		dispatch: ReduxDispatch,
		text: string | JSX.Element,
		options: DialogInfo = {},
		callbacks?: typeof globals.dialogCallbacks,
	): Promise<void> => {
		if (isIframe && typeof text === "string") {
			const hasPosted = postMessage({
				action: "alert",
				text: text,
			});
			if (hasPosted) {
				return;
			}
		}
		await _showDialog(dispatch, text, options, callbacks);
	};

	const startCountdown = (): void => {
		remainingSeconds.current = 60;
		setSeconds(remainingSeconds.current);
		const intervalId = setInterval(() => {
			if (remainingSeconds.current > 0) {
				remainingSeconds.current--;
				setSeconds(remainingSeconds.current);
			} else {
				clearInterval(intervalId);
			}
		}, 1000);
	};

	const submitLogin = async (
		captchaResponse: CaptchaResponse = {},
	): Promise<void> => {
		const isEmail = emailOrPhone.includes("@");
		const accountFormatted = isEmail
			? formatEmail(emailOrPhone)
			: formatPhoneNumber(emailOrPhone);
		if (
			!globals.emailPattern.test(accountFormatted) &&
			!globals.phonePattern.test(accountFormatted)
		) {
			await showDialog(dispatch, t("incorrectEmailOrPhoneFormat"));
			emailOrPhoneInput.current?.focus();
			return;
		}

		if (!password) {
			await showDialog(dispatch, t("enterPassword"));
			passwordInput.current?.focus();
			return;
		}

		if (tab !== LoginTab.LOGIN && code.length !== 6) {
			await showDialog(dispatch, t("incorrectVerificationCode"));
			codeInput.current?.focus();
			return;
		}

		switch (tab) {
			case LoginTab.LOGIN:
			case LoginTab.SET_PHONE:
			case LoginTab.TFA_SMS:
			case LoginTab.TFA_TOTP: {
				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.logIn({
						captchaResponse: captchaResponse,
						code: code,
						emailOrPhone: accountFormatted,
						password: password,
					});
					dispatch(setIsLoadingScreenShown(false));
					await sleep(1); // fixes URL not changing
					if (data.alert) {
						if (data.nextAction) {
							if (data.nextAction === "resetPassword") {
								incorrectPasswordCount.current++;
								if (incorrectPasswordCount.current >= 3) {
									goToPage(data.nextAction);
								} else {
									passwordInput.current?.focus();
								}
							} else {
								goToPage(data.nextAction);
							}
						}
						await showDialog(dispatch, data.alert, {
							showCancel: !!data.link,
						});
						if (data.link) {
							window.open(data.link);
						}
					} else if (data.block) {
						saveLogin(data);
						void navigate("/blocked", {
							replace: true,
							state: data.block,
						});
					} else if (data.nextAction) {
						saveLogin(data);
						goToPage(data.nextAction);
					} else if (data.token) {
						void loginCallback(data);
					}
				} catch (error) {
					if (error instanceof Response && error.status === 418) {
						// user status is REFUSE_RESPONSE
						return;
					}
					api.handleApiError(dispatch, error);
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			case LoginTab.SIGN_UP:
			case LoginTab.RESET_PASSWORD: {
				if (confirmPassword !== password) {
					await showDialog(dispatch, t("passwordsNotMatch"));
					confirmPasswordInput.current?.focus();
					return;
				}

				if (!isPasswordSecure(password, true)) {
					passwordInput.current?.focus();
					return;
				}

				dispatch(setIsLoadingScreenShown(true));
				try {
					const data = await api.setPassword(
						accountFormatted,
						code,
						password,
					);
					dispatch(setIsLoadingScreenShown(false));
					if (data.alert) {
						void showDialog(dispatch, data.alert);
					}
					if (!data.success) {
						return;
					}
					if (!data.alert) {
						void showDialog(dispatch, t("passwordSet"));
					}
					setTab(LoginTab.LOGIN);
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
				break;
			}
			default:
				break;
		}
	};

	const submitWhenEnterPressed = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (event.currentTarget.value) {
				handleSubmitClick(event);
			}
		}
	};

	const tabs = ["login", "signUp", "resetPassword"];
	const tabsElem = tabs.map((item, index) => {
		return (
			<Tab
				active={tab}
				key={item}
				index={index}
				text={item}
				updateActive={setTab}
			/>
		);
	});

	if (window.location.pathname !== "/login") {
		window.history.replaceState(null, "", "/login");
	}

	return (
		<div className={styles["login-page"]}>
			<div className={clsx("bg-img", styles["bg-img"])}></div>
			<main>
				<div className={styles["title-container"]}>
					{globals.isDesktopApp ? (
						<div className={styles["icon"]}></div>
					) : (
						<div
							className={styles["icon"]}
							role="link"
							tabIndex={0}
							title={t("home")}
							onClick={handleLogoClick}
							onKeyDown={handleKeyboardClick(handleLogoClick)}
						></div>
					)}
					<h1>{t("retieheAccount")}</h1>
				</div>
				{tab <= LoginTab.RESET_PASSWORD && (
					<div className={styles["tabs"]}>{tabsElem}</div>
				)}
				{tab >= LoginTab.SET_PHONE && tab <= LoginTab.TFA_TOTP && (
					<div
						className="description"
						dangerouslySetInnerHTML={{
							__html: ((): string => {
								switch (tab) {
									case LoginTab.SET_PHONE: {
										return t("requirePhoneNumber");
									}
									case LoginTab.TFA_SMS: {
										return t("tfaSmsTip");
									}
									case LoginTab.TFA_TOTP: {
										return t("tfaTotpTip");
									}
									default: {
										return "";
									}
								}
							})(),
						}}
					></div>
				)}
				<form onSubmit={preventDefault}>
					{tab < LoginTab.SET_PHONE && (
						<input
							ref={emailOrPhoneInput}
							name="email"
							type={
								tab === LoginTab.SIGN_UP &&
								signUpMethod === SignUpMethod.PHONE
									? "tel"
									: "email"
							}
							value={emailOrPhone}
							autoComplete="username"
							placeholder={
								tab === LoginTab.SIGN_UP
									? {
											[SignUpMethod.ALL]:
												t("emailOrPhoneNumber"),
											[SignUpMethod.EMAIL]: t("email"),
											[SignUpMethod.PHONE]:
												t("phoneNumber"),
										}[signUpMethod]
									: t("emailOrPhoneNumber")
							}
							onChange={handleInputChange(setEmailOrPhone)}
							onKeyDown={handleEmailOrPhoneKeyDown}
						/>
					)}
					{tab === LoginTab.SET_PHONE && (
						<input
							type="tel"
							value={phoneNumber}
							autoComplete="tel-national"
							placeholder={t("phoneNumber")}
							onChange={handleInputChange(setPhoneNumber)}
							onKeyDown={handlePhoneKeyDown}
						/>
					)}
					{tab >= LoginTab.SIGN_UP && tab <= LoginTab.TFA_TOTP && (
						<div className={styles["input-btn-bar"]}>
							<input
								ref={codeInput}
								type="tel"
								value={code}
								autoComplete="one-time-code"
								inputMode="numeric"
								placeholder={t("code")}
								onChange={handleInputChange(setCode)}
								onKeyDown={submitWhenEnterPressed}
							/>
							{tab >= LoginTab.SIGN_UP &&
								tab <= LoginTab.TFA_SMS && (
									<button
										type="button"
										disabled={seconds > 0}
										onClick={handleSendCodeClick}
									>
										{seconds
											? t("remainingSeconds", {
													seconds: seconds,
												})
											: t("getCode")}
									</button>
								)}
						</div>
					)}
					{tab >= LoginTab.LOGIN &&
						tab <= LoginTab.RESET_PASSWORD && (
							<input
								ref={passwordInput}
								name="password"
								type="password"
								value={password}
								autoComplete={
									tab >= LoginTab.SIGN_UP
										? "new-password"
										: "current-password"
								}
								placeholder={t("password")}
								onBlur={handlePasswordBlur}
								onChange={handlePasswordChange}
								onFocus={handlePasswordFocus}
								onKeyDown={handlePasswordKeyDown}
							/>
						)}
					{tab >= LoginTab.SIGN_UP &&
						tab <= LoginTab.RESET_PASSWORD &&
						isPasswordRequirementsShown && (
							<PasswordChecker
								passwordChecklist={passwordChecklist}
							/>
						)}
					{tab >= LoginTab.SIGN_UP &&
						tab <= LoginTab.RESET_PASSWORD && (
							<input
								ref={confirmPasswordInput}
								type="password"
								value={confirmPassword}
								autoComplete="new-password"
								placeholder={t("confirmPassword")}
								onChange={handleInputChange(setConfirmPassword)}
								onKeyDown={submitWhenEnterPressed}
							/>
						)}
					<button
						type="submit"
						onClick={handleSubmitClick}
					>
						{t(
							tab <= LoginTab.RESET_PASSWORD
								? tabs[tab]
								: "submit",
						)}
					</button>
				</form>
			</main>
			{!globals.isIosApp && <LoginFooter />}
		</div>
	);
}

export default LoginPage;
