/* eslint-disable @typescript-eslint/explicit-function-return-type */

export async function importJsEncrypt() {
	return (await import("jsencrypt")).default;
}

export async function importOtpAuth() {
	return await import("otpauth");
}

export function loadDependencies(): void {
	window.setTimeout(() => {
		void importJsEncrypt();
		void importOtpAuth();
	}, 1000);
}
