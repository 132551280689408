import globals from "@/globals";
import styles from "@/styles/LoginFooter.module.css";
import { t } from "i18next";
import { JSX } from "react";

function LoginFooter(): JSX.Element {
	if (globals.isDesktopApp) {
		return <></>;
	}
	return (
		<footer className={styles["footer"]}>
			&copy; {new Date().getFullYear()}{" "}
			<a
				className={styles["link"]}
				href={globals.home}
				target="_blank"
				rel="noreferrer"
			>
				{t("fuzhouRetieheSoftwareCoLtd")}
			</a>
			<span className="text-divider"></span>
			<a
				className={styles["link"]}
				href="https://docs.retiehe.com/tos.html"
				target="_blank"
				rel="noreferrer"
			>
				{t("termsOfService")}
			</a>
			<span className="text-divider"></span>
			<a
				className={styles["link"]}
				href="https://docs.retiehe.com/privacy.html"
				target="_blank"
				rel="noreferrer"
			>
				{t("privacyPolicy")}
			</a>
		</footer>
	);
}

export default LoginFooter;
