import * as api from "@/api";
import ActionButton from "@/components/ActionButton";
import ButtonBlock from "@/components/ButtonBlock";
import DeviceRow from "@/components/DeviceRow";
import DeviceRowCurrent from "@/components/DeviceRowCurrent";
import ImportantTip from "@/components/ImportantTip";
import LoadingScreen from "@/components/LoadingScreen";
import QrCode from "@/components/QrCode";
import Switch from "@/components/Switch";
import { importOtpAuth } from "@/dependencies";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import { DeviceResponse, ProfileResponse } from "@/types";
import {
	changePhone,
	deleteAccount,
	encodeData,
	logOut,
	showDialog,
	showPrompt,
	sleep,
} from "@/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { ChangeEvent, JSX, MouseEvent, useRef, useState } from "react";

function SecurityPage(): JSX.Element {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const login = useAppSelector((state) => state.app.login);

	const {
		data: devices,
		error: devicesError,
		isLoading: isLoadingDevices,
	} = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getAllDevices(() => {
				void logOut(dispatch);
			});
		},
		queryKey: ["devices", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});
	const {
		data: profile,
		error: profileError,
		isLoading: isLoadingProfile,
	} = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getProfile(["realId", "regTime"], () => {
				void logOut(dispatch);
			});
		},
		queryKey: ["profile", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const [otpUri, setOtpUri] = useState<string>("");
	const [showTfaQrCode, setShowTfaQrCode] = useState<boolean>(false);

	const error = devicesError || profileError;
	const isLoading = isLoadingDevices || isLoadingProfile;
	const securityDiv = useRef<HTMLDivElement>(null);

	const confirmAllLogOut = async (): Promise<void> => {
		await showDialog(dispatch, t("confirmAllLogOut"), {
			showCancel: true,
		});
		dispatch(setIsLoadingScreenShown(true));
		try {
			await api.logOutAllDevices();
			localStorage.clear();
			window.location.reload();
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const handleAllLogOutClick = (): void => {
		void confirmAllLogOut();
	};

	const handleDeleteAccountClick = (): void => {
		void deleteAccount(dispatch);
	};

	const handleTfaChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (profile?.tfa) {
			void saveTfa("0");
			event.target.parentElement?.focus();
		} else {
			setupTotp();
		}
	};

	const handleTfaContextMenu = (
		event: MouseEvent<HTMLLabelElement>,
	): void => {
		event.preventDefault();
		if (login.phone && !showTfaQrCode) {
			const newValue = !profile?.tfa;
			void saveTfa(newValue ? "1" : "0");
		}
	};

	const logOutDevice = async (id: number): Promise<void> => {
		dispatch(setIsLoadingScreenShown(true));
		try {
			await api.logOutDevice(id);
			queryClient.setQueryData<DeviceResponse>(
				["devices", login.username],
				(devices) => {
					if (!devices) {
						return devices;
					}
					return {
						...devices,
						other: devices.other.filter((item) => item.id !== id),
					};
				},
			);
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
		securityDiv.current?.focus();
	};

	const saveTfa = async (newValue: string): Promise<void> => {
		if (!login.username) {
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.updateTfa(newValue);
			if (data.error) {
				switch (data.error) {
					case "notAllowed": {
						void showDialog(dispatch, t("cannotTurnOffTfa"));
						break;
					}
					case "phoneRequired": {
						void changePhone(dispatch);
						break;
					}
					default: {
						void showDialog(dispatch, data.error);
						break;
					}
				}
			} else {
				queryClient.setQueryData<ProfileResponse>(
					["profile", login.username],
					(profile) => {
						if (!profile) {
							return;
						}
						return {
							...profile,
							tfa: newValue !== "0",
						};
					},
				);
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const setupTotp = (): void => {
		if (showTfaQrCode) {
			setShowTfaQrCode(false);
			return;
		} else if (profile?.tfa || !login.username) {
			return;
		}
		const secret = getRandomBase32(16);
		setOtpUri(
			"otpauth://totp/retiehe.com:" +
				encodeURIComponent(
					login.email || login.phone || login.username,
				) +
				"?" +
				encodeData({
					issuer: "retiehe.com",
					secret: secret,
				}),
		);
		setShowTfaQrCode(true);
	};

	const verifyOtp = async (): Promise<void> => {
		const OTPAuth = await importOtpAuth();
		const otpAuth = OTPAuth.URI.parse(otpUri);
		const verifyOtpPrompt = async (): Promise<void> => {
			const code = await showPrompt(
				dispatch,
				t("enterCodeFromGoogleAuthenticator"),
				{
					autoComplete: "one-time-code",
					type: "tel",
				},
			);
			const correctCode = otpAuth.generate();
			if (code === correctCode) {
				setShowTfaQrCode(false);
				void saveTfa(otpAuth.secret.base32);
			} else {
				await sleep(globals.ANIMATION_WAIT_TIME);
				await showDialog(dispatch, t("incorrectVerificationCode"));
				await sleep(globals.ANIMATION_WAIT_TIME);
				void verifyOtpPrompt();
			}
		};
		void verifyOtpPrompt();
	};

	const deviceRowElem = devices?.other.map((item) => {
		return (
			<DeviceRow
				item={item}
				key={item.id}
				handleLogOutClick={(): void => {
					void logOutDevice(item.id);
				}}
			/>
		);
	});

	return (
		<main
			ref={securityDiv}
			className="security"
		>
			<section>
				<Switch
					checked={profile?.tfa || false}
					onChange={handleTfaChange}
					onContextMenu={handleTfaContextMenu}
					title={t("tfaSwitch")}
				/>
				<h2>{t("tfa")}</h2>
				<div className="description">{t("tfaDescription")}</div>
			</section>
			<div className="line"></div>
			<QrCode
				buttonText="next"
				description="scanThisQrCodeUsingGoogleAuthenticator"
				qrCode={otpUri}
				scrollIntoView={false}
				show={showTfaQrCode}
				onClick={() => void verifyOtp()}
			/>
			<section>
				<div className="section-heading-container">
					<h2>{t("devices")}</h2>
					<ActionButton onClick={handleAllLogOutClick}>
						{t("allLogOut")}
					</ActionButton>
				</div>
				<div className="device-rows">
					{devices?.current && (
						<DeviceRowCurrent item={devices.current} />
					)}
					{deviceRowElem}
				</div>
			</section>
			<div className="line"></div>
			<section>
				<h2>{t("deleteAccount")}</h2>
				<div className="description">
					{t("deleteAccountDescription")}
				</div>
				<ButtonBlock
					className="danger"
					onClick={handleDeleteAccountClick}
				>
					{t("deleteAccount")}
				</ButtonBlock>
			</section>
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isLoading && <LoadingScreen />}
		</main>
	);
}

function getRandomBase32(length: number): string {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567".split("");
	let result = "";
	for (let i = 0; i < length; i++) {
		result += getRandomArrayItem<string>(chars);
	}
	return result;
}

function getRandomArrayItem<T>(array: T[]): T {
	if (!array) {
		return array;
	}
	return array[Math.floor(Math.random() * array.length)];
}

export default SecurityPage;
