import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setPrompt } from "@/redux/reducers/app";
import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { t } from "i18next";
import {
	ChangeEvent,
	FormEvent,
	JSX,
	useEffect,
	useId,
	useRef,
	useState,
} from "react";

function Prompt(): JSX.Element {
	const dispatch = useAppDispatch();

	const prompt = useAppSelector((state) => state.app.prompt);

	const id = useId();

	const [inputValue, setInputValue] = useState<string>("");
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const timeoutId = useRef<number | undefined>(undefined);

	const closeDialog = (): void => {
		window.clearTimeout(timeoutId.current);
		globals.promptCallback = null;
		globals.promptInlineAction = null;
		setInputValue("");
		setIsClosing(true);
		setTimeout(() => {
			dispatch(setPrompt({}));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleInlineActionClick = (): void => {
		void globals.promptInlineAction?.();
		closeDialog();
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		void globals.promptCallback?.(inputValue);
		closeDialog();
	};

	window.clearTimeout(timeoutId.current);
	if (prompt.countdown) {
		timeoutId.current = window.setTimeout(() => {
			dispatch(
				setPrompt({
					...prompt,
					countdown:
						prompt.countdown && prompt.countdown > 0
							? prompt.countdown - 1
							: 0,
				}),
			);
		}, 1000);
	}

	useEffect(() => {
		if (prompt.defaultText) {
			setInputValue(prompt.defaultText);
		}
	}, [prompt.defaultText]);

	return (
		<Dialog
			onClose={closeDialog}
			open={!!prompt.text}
		>
			<div
				className={clsx(
					"overlay",
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<form
					className="popup msg-box"
					onSubmit={handleSubmit}
				>
					<label
						className="msg-box-txt"
						htmlFor={id}
					>
						{prompt.text}
						{prompt.inlineActionName && (
							<>
								{" "}
								<span
									className={
										prompt.countdown && prompt.countdown > 0
											? undefined
											: "link"
									}
									role="button"
									tabIndex={0}
									onClick={handleInlineActionClick}
									onKeyDown={handleInlineActionClick}
								>
									{prompt.countdown &&
									prompt.countdown > 0 &&
									prompt.countdownText
										? t(prompt.countdownText, {
												seconds: prompt.countdown,
											})
										: prompt.inlineActionName}
								</span>
							</>
						)}
					</label>
					<input
						autoComplete="off"
						className="msg-box-input"
						data-autofocus
						id={id}
						inputMode={
							prompt.type === "tel" ? "numeric" : undefined
						}
						type={prompt.type || "text"}
						value={inputValue}
						onChange={handleInputChange}
					/>
					<div className="btn-bar">
						<button
							type="button"
							onClick={closeDialog}
						>
							{t("cancel")}
						</button>
						<button
							className="default-btn"
							type="submit"
						>
							{t("ok")}
						</button>
					</div>
				</form>
			</div>
		</Dialog>
	);
}

export default Prompt;
