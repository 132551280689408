import * as api from "@/api";
import ImportantTip from "@/components/ImportantTip";
import LoadingScreen from "@/components/LoadingScreen";
import ProfileRow from "@/components/ProfileRow";
import RealIdVerification from "@/components/RealIdVerification";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsLoadingScreenShown, setLogin } from "@/redux/reducers/app";
import { ProfileResponse, ProfileRowItem, RealIdLevel } from "@/types";
import {
	changeEmail,
	changePhone,
	logOut,
	showDialog,
	showPrompt,
} from "@/utils";
import {
	faCakeCandles,
	faCalendarDay,
	faEarth,
	faEnvelope,
	faIdCard,
	faKey,
	faMobileScreen,
	faSignature,
	faUser,
	faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { JSX, useState } from "react";
import { useNavigate } from "react-router-dom";

interface JsxItem {
	jsx: JSX.Element;
}

function ProfilePage(): JSX.Element {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const login = useAppSelector((state) => state.app.login);

	const { data, error, isFetching, refetch } = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getProfile(["realId", "regTime"], () => {
				void logOut(dispatch);
			});
		},
		queryKey: ["profile", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const [showRealIdVerification, setShowRealIdVerification] =
		useState<boolean>(false);

	const changeBirthday = async (): Promise<void> => {
		const newBirthday = await showPrompt(dispatch, t("enterNewBirthday"), {
			defaultText:
				login.birthday || new Date().toISOString().split("T")[0],
		});
		if (!login.username || !newBirthday) {
			return;
		}
		const newBirthdayDate = new Date(newBirthday);
		const formattedNewBirthday =
			newBirthdayDate.toString() !== "Invalid Date"
				? newBirthdayDate.toISOString().split("T")[0]
				: "";
		const year = newBirthdayDate.getFullYear();
		if (
			!formattedNewBirthday ||
			year < 1900 ||
			year > new Date().getFullYear()
		) {
			void showDialog(dispatch, t("invalidDate"));
			return;
		}
		if (formattedNewBirthday !== login.birthday) {
			dispatch(setIsLoadingScreenShown(true));
			try {
				await api.updateBirthday(formattedNewBirthday);
				globals.login.birthday = formattedNewBirthday;
				dispatch(setLogin(globals.login));
			} catch (error) {
				api.handleApiError(dispatch, error);
			} finally {
				dispatch(setIsLoadingScreenShown(false));
			}
		}
	};

	const changeName = async (): Promise<void> => {
		const newName = await showPrompt(dispatch, t("enterNewName"), {
			defaultText: login.name,
		});
		if (!login.username || !newName || newName === login.name) {
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.updateName(newName);
			if (data.alert) {
				void showDialog(dispatch, data.alert);
			}
			if (!data.success) {
				return;
			}
			globals.login.name = newName;
			dispatch(setLogin(globals.login));
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const copyUserId = async (): Promise<void> => {
		if (!login.username) {
			return;
		}
		try {
			if (!("clipboard" in navigator)) {
				throw new Error("Clipboard API not supported.");
			}
			await navigator.clipboard.writeText(login.username);
			void showDialog(dispatch, t("copiedToClipboard"));
		} catch (error) {
			console.error(error);
			void showPrompt(dispatch, t("clipboardNotSupported"), {
				defaultText: login.username,
			});
		}
	};

	const updateRegion = async (): Promise<void> => {
		// overseas account transfer to China
		if (globals.dynamicInfo.region === "CN" && !login.phone) {
			return changePhone(dispatch);
		}

		// China account transfer to overseas
		const isChinaTimeZone =
			Intl.DateTimeFormat().resolvedOptions().timeZone ===
			"Asia/Shanghai";
		if (data?.region === "CN" && isChinaTimeZone) {
			return void showDialog(
				dispatch,
				t("regionUpdatedAs", {
					region: "CN",
				}),
			);
		}

		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.updateRegion();
			if (data.error) {
				return showDialog(dispatch, data.error);
			}
			if (!data.success) {
				return;
			}
			queryClient.setQueryData<ProfileResponse>(
				["profile", login.username],
				(profile) => {
					if (!profile || !data.newValue) {
						return;
					}
					return {
						...profile,
						region: data.newValue,
					};
				},
			);
			void showDialog(
				dispatch,
				t("regionUpdatedAs", {
					region: data.newValue,
				}),
			);
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const rows: (ProfileRowItem | JsxItem)[] = [
		{
			action: {
				onClick: () => void copyUserId(),
				text: "copy",
				title: "copyUserId",
			},
			content: login.username,
			icon: faUser,
			label: "userId",
		},
		{
			action: {
				onClick: (): void => {
					void changeEmail(dispatch, login.email);
				},
				text: "change",
				title: "changeEmail",
			},
			content: login.email,
			icon: faEnvelope,
			label: "email",
		},
		{
			action: {
				onClick: (): void => {
					void changePhone(dispatch);
				},
				text: "change",
				title: "changePhoneNumber",
			},
			content: login.phone,
			icon: faMobileScreen,
			label: "phoneNumber",
		},
		{
			action: {
				onClick: (): void => {
					void navigate("/.well-known/change-password", {
						state: {
							emailOrPhone: login.email || login.phone,
						},
					});
				},
				text: "change",
				title: "changePassword",
			},
			content: login.token ? "********" : "",
			icon: faKey,
			label: "password",
		},
		{
			action: {
				onClick: changeName,
				text: "change",
				title: "changeName",
			},
			content: login.name,
			icon: faSignature,
			label: "name",
		},
		{
			action: {
				onClick: changeBirthday,
				text: "change",
				title: "changeBirthday",
			},
			content: login.birthday,
			icon: faCakeCandles,
			label: "birthday",
		},
		{
			action: {
				onClick: updateRegion,
				text: "update",
				title: "",
			},
			content: data?.region,
			icon: faEarth,
			label: "region",
		},
		{
			action: {
				onClick: (): void => {
					setShowRealIdVerification(!showRealIdVerification);
				},
				text: "change",
				title: "changeRealId",
			},
			content:
				data?.realName !== undefined
					? data.realName || t("idCardNotUploaded")
					: undefined,
			icon: faIdCard,
			label: "realId",
		},
		{
			jsx: (
				<RealIdVerification
					callback={() => void refetch()}
					key="qrCodeForRealIdVerification"
					show={showRealIdVerification}
				/>
			),
		},
		{
			action: {
				onClick: (): void => {
					void showDialog(dispatch, t("realIdLevelDescription"));
				},
				text: "details",
				title: "realIdLevelDetails",
			},
			content:
				data?.realIdLevel !== undefined
					? data.realIdLevel > RealIdLevel.NO_REAL_ID
						? t("level" + data.realIdLevel)
						: t("noRealId")
					: undefined,
			icon: faUserCheck,
			label: "realIdLevel",
		},
		{
			action: {
				onClick: (): void => {
					const details = data?.regTime?.details;
					if (!details) {
						return;
					}
					void showDialog(dispatch, details);
				},
				text: "details",
				title: "regTimeDetails",
			},
			content: data?.regTime?.time,
			icon: faCalendarDay,
			label: "regTime",
		},
	];
	const rowsElem = rows.map((item) => {
		if (item.jsx) {
			return item.jsx;
		}
		const profileRowItem = item as ProfileRowItem;
		return (
			<ProfileRow
				item={profileRowItem}
				key={profileRowItem.label}
			/>
		);
	});

	return (
		<main className="profile">
			{rowsElem}
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isFetching && <LoadingScreen />}
		</main>
	);
}

export default ProfilePage;
