import { ForwardedRef, JSX, ReactNode, forwardRef } from "react";

interface ActionButtonProps {
	children: ReactNode;
	onClick: () => void;
	title?: string;
}

function ActionButton(
	{ children, onClick, title }: ActionButtonProps,
	ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
	return (
		<button
			ref={ref}
			className="action-button"
			type="button"
			title={title}
			onClick={onClick}
		>
			{children}
		</button>
	);
}

export default forwardRef(ActionButton);
