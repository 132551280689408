import styles from "@/styles/ButtonBlock.module.css";
import clsx from "clsx";
import { JSX, MouseEventHandler, ReactNode } from "react";

interface ButtonBlockProps {
	children: ReactNode;
	className?: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
}

function ButtonBlock({
	children,
	className = "",
	onClick,
}: ButtonBlockProps): JSX.Element {
	return (
		<button
			className={clsx(styles["btn-block"], styles[className])}
			type="button"
			onClick={onClick}
		>
			{children}
		</button>
	);
}

export default ButtonBlock;
