import { DialogInfo, LoginInfo, PromptInfo } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	dialog: {} as DialogInfo,
	isCaptchaShown: false,
	isLoadingScreenShown: window.location.pathname !== "/login",
	login: {} as LoginInfo,
	prompt: {} as PromptInfo,
	region: "",
};

const slice = createSlice({
	initialState: initialState,
	name: "app",
	reducers: {
		setDialog: (state, action) => {
			const newValue = action.payload as DialogInfo;
			state.dialog = newValue;
		},
		setIsCaptchaShown: (state, action) => {
			const newValue = action.payload as boolean;
			state.isCaptchaShown = newValue;
		},
		setIsLoadingScreenShown: (state, action) => {
			const newValue = action.payload as boolean;
			state.isLoadingScreenShown = newValue;
		},
		setLogin: (state, action) => {
			const newValue = action.payload as LoginInfo;
			state.login = { ...newValue };
		},
		setPrompt: (state, action) => {
			const newValue = action.payload as PromptInfo;
			state.prompt = newValue;
		},
		setRegion: (state, action) => {
			const newValue = action.payload as string;
			state.region = newValue;
		},
	},
});

export const {
	setDialog,
	setIsCaptchaShown,
	setIsLoadingScreenShown,
	setLogin,
	setPrompt,
	setRegion,
} = slice.actions;
export default slice.reducer;
