import { OptionsItem } from "@/types";
import { handleKeyboardClick } from "@/utils";
import { JSX } from "react";
import { Fragment } from "react/jsx-runtime";

interface OptionsProps<T> {
	active?: T;
	items: OptionsItem<T>[];
	updateActive: (newValue: T) => void;
}

function Options<T extends string>({
	active,
	items,
	updateActive,
}: OptionsProps<T>): JSX.Element {
	const itemsElem = items.map((item) => {
		if (item.when === false) {
			return <Fragment key={item.value}></Fragment>;
		}

		const handleClick = (): void => {
			if (item.value) {
				updateActive(item.value);
			}
		};

		return (
			<div
				key={item.value}
				className="option"
				tabIndex={0}
				role="checkbox"
				aria-checked={active === item.value}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				{item.text}
			</div>
		);
	});
	return <div>{itemsElem}</div>;
}

export default Options;
