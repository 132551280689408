import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { JSX } from "react";

export interface AppList<T> {
	airportal?: T;
	all?: T;
	rthe?: T;
}

export enum BlockedTab {
	BLOCKED,
	REAL_ID_REQUIRED,
	PLEDGE,
}

export interface ButtonsAction {
	cancel?: (() => void) | null;
	ok: (() => void) | null;
}

export interface DeviceInfo {
	id: number;
	ip: string;
	userAgent: string;
}

export interface DeviceResponse {
	current: DeviceInfo;
	other: DeviceInfo[];
}

export interface DialogInfo {
	countdown?: number;
	isHtml?: boolean;
	showCancel?: boolean;
	text?: string;
	title?: string;
}

export interface ExpirationTimeInfo {
	data?: number;
	time?: number;
}

export interface InitResponse {
	alert?: string;
	blockReason?: string;
	isTwinkstar?: boolean;
	login?: string;
	redirectTo?: string;
	region?: string;
	sw?: boolean;
}

export interface LoginInfo {
	birthday?: string;
	email?: string;
	name?: string;
	phone?: string;
	token?: string;
	username?: string;
}

export enum LoginTab {
	LOGIN,
	SIGN_UP,
	RESET_PASSWORD,
	SET_PHONE,
	TFA_SMS,
	TFA_TOTP,
}

export interface LoginResponse extends LoginInfo {
	action?: string;
	block?: {
		autoBlock: boolean;
		reason: string;
	};
	alert?: string;
	link?: string;
	nextAction?: string;
	success?: boolean;
}

interface MobileAppCallback {
	postMessage: (data: string) => void;
}

export interface OptionsItem<T> {
	text?: string;
	value?: T;
	when?: boolean;
}

export interface OrderInfo {
	amount: number;
	method: PaymentMethod;
	orderNumber: string;
	product: string;
	time: number;
}

export interface PasswordChecklist {
	hasLowercase: boolean;
	hasNoCommonCombinations: boolean;
	hasNoConsecutiveNumbers: boolean;
	hasUppercase: boolean;
	isLongEnough: boolean;
}

export enum PaymentMethod {
	ALIPAY = "alipay",
	APP_STORE = "appstore",
	PAYPAL = "paypal",
	WECHAT_PAY = "wechatpay",
}

export interface PaymentMethodInfo {
	callback: (options: {
		dispatch: ReduxDispatch;
		url: string;
		updateQrCode?: (newValue: string) => void;
		updateShowCashier: (newValue: boolean) => void;
	}) => Promise<void>;
}

export interface PaymentQuery {
	amount?: number;
	appName?: string;
	month?: number;
	[key: string]: string | number | undefined;
}

export interface PlanLength {
	"month-1": number | PriceDetail;
	"month-3": number | PriceDetail;
	"month-12": number | PriceDetail;
}

export interface PremiumInfo {
	appName?: string;
	copy?: string;
	data?: string;
	days?: number;
	explanation?: string;
	icon?: string;
	iconFallback?: string;
	link?: string;
	originalPrice?: PlanLength;
	price?:
		| PlanLength
		| PriceDetail
		| {
				airportal: PriceDetail;
				rthe: PriceDetail;
		  };
	privileges?: string[];
	promotionText?: string;
}

export interface PriceDetail {
	cny?: number;
	original: number;
	special: number;
}

export interface PriceItem {
	key?: number;
	originalPrice?: number;
	price?: number;
	text?: string;
}

export interface ProfileResponse {
	realIdLevel?: RealIdLevel;
	realName?: string;
	region: string;
	regTime?: {
		details: string;
		time: string;
	};
	tfa: boolean;
}

export interface ProfileRowItem {
	action: {
		onClick: () => void | Promise<void>;
		text: string;
		title: string;
	};
	content?: string;
	icon: IconProp;
	jsx?: JSX.Element;
	label: string;
}

export interface PromptInfo {
	autoComplete?: string;
	countdown?: number;
	countdownText?: string;
	defaultText?: string;
	inlineActionName?: string;
	text?: string;
	type?: string;
}

export enum RealIdLevel {
	NO_REAL_ID,
	EMAIL_VERIFIED,
	PHONE_VERIFIED,
	REAL_ID_VERIFIED,
}

export type ReduxDispatch = (action: {
	type: string;
	payload?: unknown;
}) => void;

export interface WindowExt extends Window {
	rth?: MobileAppCallback;
	TencentCaptcha?: typeof TencentCaptcha;
	webkit?: {
		messageHandlers: {
			rth: MobileAppCallback;
		};
	};
}
