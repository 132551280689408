import * as api from "@/api";
import ImportantTip from "@/components/ImportantTip";
import LoadingScreen from "@/components/LoadingScreen";
import { useAppSelector } from "@/redux/hooks";
import styles from "@/styles/OrderHistoryPage.module.css";
import { PaymentMethod } from "@/types";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { JSX } from "react";

function OrderHistoryPage(): JSX.Element {
	const login = useAppSelector((state) => state.app.login);

	const { data, error, isLoading } = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getOrders();
		},
		queryKey: ["orders", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const methodNameMap = {
		alipay: t("alipay"),
		appstore: t("appStore"),
		paypal: "PayPal",
		wechatpay: t("wechatPay"),
	};

	const tableRows = data?.map((order) => {
		const amount =
			order.amount.toFixed(2) +
			" " +
			(order.method === PaymentMethod.PAYPAL ? t("usd") : t("cny"));
		const method = methodNameMap[order.method];
		const time = new Date(order.time).toLocaleString();

		return (
			<tr key={order.orderNumber}>
				<td>{order.orderNumber}</td>
				<td>{order.product}</td>
				<td>{time}</td>
				<td>{amount}</td>
				<td>{method}</td>
			</tr>
		);
	});

	return (
		<main>
			<h2>{t("orderHistory")}</h2>
			<div className="description">
				{t("onlyOrdersFromSeptember2023OnwardShown")}
			</div>
			<div className={styles["table-container"]}>
				<table>
					<thead>
						<tr>
							<th>{t("orderNumber")}</th>
							<th>{t("product")}</th>
							<th>{t("createdTime")}</th>
							<th>{t("amount")}</th>
							<th>{t("paymentMethod")}</th>
						</tr>
					</thead>
					<tbody>{tableRows}</tbody>
				</table>
			</div>
			<div className={styles["bottom-tip"]}>
				{t("noMoreItemsToDisplay")}
				<br />
				<FontAwesomeIcon icon={faCircleQuestion} />
				{t("haveQuestionsAboutOrders")}
				<a href="mailto:support@mail.retiehe.com">
					support@mail.retiehe.com
				</a>
			</div>
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isLoading && <LoadingScreen />}
		</main>
	);
}

export default OrderHistoryPage;
