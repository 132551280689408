import OptionsPrice from "@/components/OptionsPrice";
import { AppList, PremiumInfo, PriceDetail, PriceItem } from "@/types";
import { t } from "i18next";
import { JSX } from "react";

interface OptionsPricePremiumProps {
	active: PriceItem;
	paymentMethod: string;
	premiumInfo: AppList<PremiumInfo>;
	service: string;
	updateActive: (newValue: PriceItem) => void;
}

function OptionsPricePremium({
	active,
	paymentMethod,
	premiumInfo,
	service,
	updateActive,
}: OptionsPricePremiumProps): JSX.Element {
	const isDollar = paymentMethod === "paypal";
	const currency = isDollar ? "US$" : "¥";
	const isData = service === "airportal";
	const textMap = {
		"month-1": {
			data: "10 GB",
			month: t("oneMonth"),
		},
		"month-3": {
			data: "30 GB",
			month: t("threeMonths"),
		},
		"month-12": {
			data: "120 GB",
			month: t("oneYear"),
		},
	};

	const getMonth = (key: string): number => {
		return parseInt(key.substring(6));
	};

	const yuanToDollar = (yuan: number): number => {
		return Math.ceil(yuan / 3) - 0.01;
	};

	let serviceInfo =
		premiumInfo[service.toLowerCase() as keyof typeof premiumInfo];
	if (!serviceInfo) {
		return <></>;
	}
	if (serviceInfo.copy) {
		serviceInfo = premiumInfo[serviceInfo.copy as keyof typeof premiumInfo];
	}

	const items: PriceItem[] = [];
	if (serviceInfo?.price) {
		for (const key in serviceInfo.price) {
			const item = serviceInfo.price[
				key as keyof typeof serviceInfo.price
			] as PriceDetail;
			const month = getMonth(key);
			const textMapValue = textMap[key as keyof typeof textMap];
			items.push({
				key: month,
				originalPrice:
					item.special !== item.original
						? isDollar
							? yuanToDollar(item.original)
							: item.original
						: undefined,
				price: isDollar ? yuanToDollar(item.special) : item.special,
				text: isData ? textMapValue.data : textMapValue.month,
			});
		}
	}

	return (
		<OptionsPrice
			active={active}
			currency={currency}
			items={items}
			updateActive={updateActive}
		/>
	);
}

export default OptionsPricePremium;
