import DeviceIcon from "@/components/DeviceIcon";
import { DeviceInfo } from "@/types";
import { t } from "i18next";
import { JSX } from "react";

interface DeviceRowCurrentProps {
	item: DeviceInfo;
}

function DeviceRowCurrent({ item }: DeviceRowCurrentProps): JSX.Element {
	const { ip, userAgent } = item;
	return (
		<div className="row">
			<div className="row-body">
				<DeviceIcon userAgent={userAgent} />
				<div className="label">
					{ip}
					<span>{t("currentDevice")}</span>
				</div>
				<div className="content small">{userAgent}</div>
			</div>
		</div>
	);
}

export default DeviceRowCurrent;
