import * as api from "@/api";
import ButtonBlock from "@/components/ButtonBlock";
import ImportantTip from "@/components/ImportantTip";
import LoadingScreen from "@/components/LoadingScreen";
import Options from "@/components/Options";
import OptionsPricePremium from "@/components/OptionsPricePremium";
import Paypal from "@/components/Paypal";
import PremiumRow from "@/components/PremiumRow";
import QrCode from "@/components/QrCode";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import styles from "@/styles/PremiumPage.module.css";
import {
	AppList,
	PaymentMethod,
	PaymentQuery,
	PremiumInfo,
	PriceItem,
} from "@/types";
import { logOut, showDialog } from "@/utils";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useRef, useState } from "react";

function PremiumPage(): JSX.Element {
	const dispatch = useAppDispatch();

	const login = useAppSelector((state) => state.app.login);
	const region = useAppSelector((state) => state.app.region);

	const {
		data: premiumInfo,
		error: premiumInfoError,
		isLoading: isLoadingPremiumInfo,
	} = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getPremiumInfo(() => {
				void logOut(dispatch);
			});
		},
		queryKey: ["premiumInfo", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});
	const {
		data: expirationTime,
		error: expirationTimeError,
		isFetching: isFetchingExpirationTime,
		refetch: refetchExpirationTime,
	} = useQuery({
		queryFn: () => {
			if (!login.username) {
				return null;
			}
			return api.getExpirationTime(() => {
				void logOut(dispatch);
			});
		},
		queryKey: ["expirationTime", login.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const error = premiumInfoError || expirationTimeError;
	const isLoading = isLoadingPremiumInfo || isFetchingExpirationTime;
	const main = useRef<HTMLElement>(null);
	const serviceParam = globals.params.service?.toLowerCase();

	const [currency, setCurrency] = useState<string>("¥");
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
		PaymentMethod.WECHAT_PAY,
	);
	const [paypalQuery, setPaypalQuery] = useState<PaymentQuery>({});
	const [plan, setPlan] = useState<PriceItem>({});
	const [product, setProduct] = useState<string>(serviceParam || "");
	const [qrCode, setQrCode] = useState<string>("");
	const [qrCodeDescription, setQrCodeDescription] = useState<string>("");
	const [showCashier, setShowCashier] = useState<boolean>(false);
	const [showImportantTip, setShowImportantTip] = useState<boolean>(false);

	const getDays = (timestamp?: number): number => {
		if (!timestamp) {
			return 0;
		}
		let days =
			Math.round((timestamp - new Date().getTime() / 1000) / 86400) || 0;
		if (days < 0) {
			days = 0;
		}
		return days;
	};

	const getProductInfo = (
		premiumInfo: AppList<PremiumInfo>,
		product: string,
	): PremiumInfo | null => {
		if (!premiumInfo || !product) {
			return null;
		}
		const productKey = product.toLowerCase() as keyof typeof premiumInfo;
		let productInfo = premiumInfo[productKey];
		if (!productInfo) {
			return null;
		}
		const copyKey = productInfo.copy as keyof typeof premiumInfo;
		if (copyKey && copyKey in premiumInfo) {
			productInfo = premiumInfo[copyKey];
			if (!productInfo) {
				return null;
			}
		}
		return productInfo;
	};

	const handleOptionChange = <T,>(
		callback: (newValue: T) => void,
	): ((newValue: T) => void) => {
		return (newValue: T) => {
			callback(newValue);
			hideCashier();
		};
	};

	const handlePaidClick = (): void => {
		void showDialog(dispatch, t("thankForPurchasing"));
		paid();
	};

	const handlePlaceOrderClick = (): void => {
		void placeOrder();
	};

	const hideCashier = (): void => {
		setPaypalQuery({});
		setQrCode("");
		setShowCashier(false);
	};

	const paid = (): void => {
		if (main.current) {
			main.current.scrollTop = 0;
		}
		void refetchExpirationTime();
		hideCashier();
	};

	const placeOrder = async (): Promise<void> => {
		if (!plan.price || !product) {
			void showDialog(dispatch, t("premiumSelectProductAndPlan"));
			return;
		}
		const query: PaymentQuery = {
			appName: product,
		};
		if (paymentMethod === PaymentMethod.PAYPAL) {
			query.month = plan.key;
			setPaypalQuery(query);
			return;
		}
		query.amount = plan.price * 100;
		const methodInfo =
			globals.methodMap[paymentMethod as keyof typeof globals.methodMap];
		if (!methodInfo) {
			return;
		}
		setQrCodeDescription(
			paymentMethod === PaymentMethod.WECHAT_PAY
				? t("useWechatScanAndPay")
				: "",
		);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.pay(query, paymentMethod);
			void methodInfo.callback({
				dispatch: dispatch,
				updateQrCode: setQrCode,
				updateShowCashier: setShowCashier,
				url: data.code_url,
			});
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const toGB = (bytes?: number): string => {
		if (!bytes) {
			return "0.00";
		}
		return (bytes / 1073741824).toFixed(2);
	};

	const updatePaymentMethod = (newValue: PaymentMethod): void => {
		if (
			paymentMethod === PaymentMethod.PAYPAL ||
			newValue === PaymentMethod.PAYPAL
		) {
			setPlan({});
		}
		setPaymentMethod(newValue);
		setCurrency(newValue === PaymentMethod.PAYPAL ? "US$" : "¥");
	};

	const updateProduct = (newValue: string): void => {
		setProduct(newValue);
		setPlan({});
		if (serviceParam) {
			setShowImportantTip(newValue !== serviceParam);
		}
	};

	const productInfo = premiumInfo
		? getProductInfo(premiumInfo, product)
		: null;
	const promotion = productInfo?.promotionText;

	const rows: PremiumInfo[] = [
		{
			appName: "airportal",
			data: toGB(expirationTime?.airportal?.data),
			explanation: premiumInfo?.airportal?.explanation || "",
			icon: "https://assets.retiehe.com/ap-apple-touch-icon-2.webp",
			iconFallback:
				"https://assets.retiehe.com/ap-apple-touch-icon-2.png",
			link: "https://www.airportal.cn/",
			privileges: premiumInfo?.airportal?.privileges || [],
		},
		{
			appName: "webHosting",
			days: getDays(expirationTime?.rthe?.time),
			explanation: premiumInfo?.rthe?.explanation || "",
			icon: "https://assets.retiehe.com/host-icon-512-2.png",
			link: "https://host.retiehe.com/",
			privileges: premiumInfo?.rthe?.privileges || [],
		},
	];
	const rowsElem = rows.map((item) => {
		return (
			<PremiumRow
				item={item}
				key={item.appName}
			/>
		);
	});

	const productNameMap = {
		airportal: t("airportal"),
		rthe: t("webHosting"),
	};
	const products = Object.keys(productNameMap).map((item) => {
		return {
			text: productNameMap[item as keyof typeof productNameMap],
			value: item,
		};
	});
	const paymentMethods = [
		{
			text: t("wechatPay"),
			value: PaymentMethod.WECHAT_PAY,
			when: true,
		},
		{
			text: t("alipay"),
			value: PaymentMethod.ALIPAY,
			when: true,
		},
		{
			text: t("paypalOrBankCard"),
			value: PaymentMethod.PAYPAL,
			when: !!login.email && region !== "CN",
		},
	];

	return (
		<main
			ref={main}
			className="premium"
		>
			<h2>{t("activateOrRenew")}</h2>
			<div>{rowsElem}</div>
			<section>
				<h3>{t("product")}</h3>
				<Options
					active={product}
					items={products}
					updateActive={handleOptionChange(updateProduct)}
				/>
			</section>
			{premiumInfo && productInfo && (
				<section>
					<h3>
						<span>{t("plan")}</span>
						<span className={styles["promotion"]}>
							{promotion && (
								<FontAwesomeIcon
									icon={faTags}
									fixedWidth
								/>
							)}
							{promotion}
						</span>
					</h3>
					<OptionsPricePremium
						active={plan}
						paymentMethod={paymentMethod}
						premiumInfo={premiumInfo}
						service={product}
						updateActive={setPlan}
					/>
				</section>
			)}
			<section>
				<h3>{t("paymentMethod")}</h3>
				<Options
					active={paymentMethod}
					items={paymentMethods}
					updateActive={handleOptionChange(updatePaymentMethod)}
				/>
			</section>
			{showImportantTip && (
				<ImportantTip
					text={t("premiumProductConfirmationTip", {
						initialProduct:
							productNameMap[
								serviceParam as keyof typeof productNameMap
							],
						selectedProduct:
							productNameMap[
								product as keyof typeof productNameMap
							],
					})}
				/>
			)}
			<div className={styles["sticky-button-container"]}>
				<div className={styles["price-info-container"]}>
					<div className={clsx("price", styles["price"])}>
						{currency}
						{plan.price || "--"}
					</div>
					{plan.originalPrice && plan.price && (
						<div className={styles["price-description"]}>
							{t("saved")} {currency}
							{plan.originalPrice - plan.price}
						</div>
					)}
				</div>
				<ButtonBlock
					className="place-order"
					onClick={handlePlaceOrderClick}
				>
					{t("placeOrder")}
				</ButtonBlock>
			</div>
			<QrCode
				description={qrCodeDescription}
				qrCode={qrCode}
				show={showCashier}
				onClick={handlePaidClick}
			/>
			{paymentMethod === PaymentMethod.PAYPAL && (
				<PayPalScriptProvider
					options={{
						clientId:
							"ARDoBEYYX2d7z4Jm5RHXZgpV1H_b_p2TgBNIE-SH5SY3JMtSkluytg_OxA0kiAoP7uPvhfEspAPh8gJY",
						vault: true,
					}}
				>
					<Paypal
						paid={paid}
						query={paypalQuery}
						updateQuery={setPaypalQuery}
					/>
				</PayPalScriptProvider>
			)}
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isLoading && <LoadingScreen />}
		</main>
	);
}

export default PremiumPage;
