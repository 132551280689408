import styles from "@/styles/PasswordChecker.module.css";
import { PasswordChecklist } from "@/types";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

interface PasswordCheckerProps {
	passwordChecklist: PasswordChecklist;
}

function PasswordChecker({
	passwordChecklist,
}: PasswordCheckerProps): JSX.Element {
	const items = [
		{
			label: t("lengthGreaterThan8Characters"),
			value: passwordChecklist.isLongEnough,
		},
		{
			label: t("containsLowercaseLetters"),
			value: passwordChecklist.hasLowercase,
		},
		{
			label: t("containsUppercaseLetters"),
			value: passwordChecklist.hasUppercase,
		},
		{
			label: t("doesNotContainCommonPasswordCombinations"),
			value: passwordChecklist.hasNoCommonCombinations,
		},
		{
			label: t("doesNotContainConsecutiveNumbers"),
			value: passwordChecklist.hasNoConsecutiveNumbers,
		},
	] as const;

	const elements = items.map((item) => {
		return (
			<div
				className={clsx(!!item.value && styles["fulfilled"])}
				key={item.label}
			>
				<FontAwesomeIcon icon={faCircleCheck} />
				<span>{item.label}</span>
			</div>
		);
	});

	return (
		<div className={styles["password-requirements-container"]}>
			{elements}
		</div>
	);
}

export default PasswordChecker;
