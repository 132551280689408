import { PriceItem } from "@/types";
import { handleKeyboardClick } from "@/utils";
import { JSX } from "react";

interface OptionsPriceProps {
	active: PriceItem;
	currency?: string;
	items: PriceItem[];
	updateActive: (newValue: PriceItem) => void;
}

function OptionsPrice({
	active,
	currency = "¥",
	items,
	updateActive,
}: OptionsPriceProps): JSX.Element {
	const itemsElem = items.map((item) => {
		const handleClick = (): void => {
			updateActive(item);
		};

		active.key = active.key || active.price;
		item.key = item.key || item.price;

		return (
			<div
				key={item.key}
				className="option plan"
				tabIndex={0}
				role="checkbox"
				aria-checked={active.key === item.key}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				{item.text}
				<div className="price">
					{currency + item.price}
					{item.originalPrice && (
						<del>
							{currency[currency.length - 1]}
							{item.originalPrice}
						</del>
					)}
				</div>
				{item.price && item.originalPrice && (
					<div className="promotion-tag">
						-
						{Math.ceil((1 - item.price / item.originalPrice) * 100)}
						%
					</div>
				)}
			</div>
		);
	});
	return <div>{itemsElem}</div>;
}

export default OptionsPrice;
