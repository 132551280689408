import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setDialog } from "@/redux/reducers/app";
import { Dialog, DialogTitle } from "@headlessui/react";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, MouseEvent, useRef, useState } from "react";

function MessageDialog(): JSX.Element {
	const dispatch = useAppDispatch();

	const dialog = useAppSelector((state) => state.app.dialog);

	const [isClosing, setIsClosing] = useState<boolean>(false);

	const timeoutId = useRef<number | undefined>(undefined);

	const closeDialog = (): void => {
		window.clearTimeout(timeoutId.current);
		globals.dialogCallbacks = {};
		setIsClosing(true);
		setTimeout(() => {
			dispatch(setDialog({}));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleCancelClick = (event: MouseEvent<HTMLButtonElement>): void => {
		void globals.dialogCallbacks.cancel?.(event);
		closeDialog();
	};

	const handleOkClick = (event: MouseEvent<HTMLButtonElement>): void => {
		void globals.dialogCallbacks.ok?.(event);
		closeDialog();
	};

	window.clearTimeout(timeoutId.current);
	if (dialog.countdown) {
		timeoutId.current = window.setTimeout(() => {
			dispatch(
				setDialog({
					...dialog,
					countdown:
						dialog.countdown && dialog.countdown > 0
							? dialog.countdown - 1
							: 0,
				}),
			);
		}, 1000);
	}

	return (
		<Dialog
			onClose={closeDialog}
			open={!!dialog.text}
		>
			<div
				className={clsx(
					"overlay",
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<div
					className={clsx(
						"popup",
						"msg-box",
						dialog.text && dialog.text.length > 150 && "wordy",
					)}
				>
					{dialog.title && (
						<DialogTitle as="h1">{dialog.title}</DialogTitle>
					)}
					<div className="msg-box-txt">
						{dialog.isHtml && dialog.text ? (
							<span
								dangerouslySetInnerHTML={{
									__html: dialog.text,
								}}
							></span>
						) : (
							dialog.text
						)}
					</div>
					<div className="btn-bar">
						{dialog.showCancel && (
							<button
								type="button"
								onClick={handleCancelClick}
							>
								{t("cancel")}
							</button>
						)}
						<button
							className="default-btn"
							data-autofocus
							disabled={!!dialog.countdown}
							type="button"
							onClick={handleOkClick}
						>
							{t("ok")}
							{dialog.countdown ? ` (${dialog.countdown})` : null}
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default MessageDialog;
