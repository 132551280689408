import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar";
import { JSX, ReactNode } from "react";

interface CommonShellProps {
	children: ReactNode;
}

function CommonShell({ children }: CommonShellProps): JSX.Element {
	return (
		<>
			<div className="bg-img"></div>
			<Header />
			<Sidebar />
			<div className="account">{children}</div>
		</>
	);
}

export default CommonShell;
