import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JSX } from "react";

interface DeviceIconProps {
	userAgent: string;
}

function DeviceIcon({ userAgent }: DeviceIconProps): JSX.Element {
	const icon = ((): IconProp => {
		if (userAgent.includes("Windows") || userAgent.includes("Macintosh")) {
			return "display";
		} else if (userAgent.includes("iPad")) {
			// must be before iPhone
			return "tablet-screen-button";
		} else if (
			userAgent.includes("iPhone") ||
			userAgent.includes("Android")
		) {
			return "mobile-screen-button";
		} else {
			return "globe";
		}
	})();

	return (
		<FontAwesomeIcon
			className="device-icon"
			icon={icon}
			size="xs"
			fixedWidth
		/>
	);
}

export default DeviceIcon;
