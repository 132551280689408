import * as api from "@/api";
import QrCode from "@/components/QrCode";
import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import { showDialog } from "@/utils";
import { JSX, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface RealIdVerificationProps {
	callback: () => void;
	show: boolean;
}

function RealIdVerification({
	callback,
	show,
}: RealIdVerificationProps): JSX.Element {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [qrCode, setQrCode] = useState<string>("");
	const [realIdHasCallback, setRealIdHasCallback] = useState<boolean>(false);

	const changeRealId = useCallback(async (): Promise<void> => {
		if (!globals.login.username) {
			void navigate("/login", {
				replace: true,
			});
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.createReadId();
			if (navigator.userAgent.includes("MicroMessenger")) {
				window.location.href = data.url;
			} else {
				setQrCode(data.url);
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	}, [dispatch, navigate]);

	const handleFinishedRealIdVerificationClick = (): void => {
		void verifyRealId();
	};

	const verifyRealId = async (): Promise<void> => {
		if (!globals.login.username) {
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.verifyReadId();
			if (!data.success) {
				if (data.alert) {
					void showDialog(dispatch, data.alert);
				}
				return;
			}
			if (realIdHasCallback && document.referrer) {
				window.location.href = document.referrer;
			} else {
				callback();
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	useEffect(() => {
		if (
			globals.login.username &&
			globals.params.action === "changeRealName"
		) {
			delete globals.params.action;
			setRealIdHasCallback(true);
			void changeRealId();
		} else if (show) {
			void changeRealId();
		} else {
			setQrCode("");
		}
	}, [changeRealId, show]);

	return (
		<QrCode
			buttonText="finishedRealIdVerification"
			description="useWechatScanQrCodeForRealId"
			qrCode={qrCode}
			show={!!qrCode && show}
			size={200}
			onClick={handleFinishedRealIdVerificationClick}
		/>
	);
}

export default RealIdVerification;
