import * as api from "@/api";
import globals from "@/globals";
import {
	setDialog,
	setIsCaptchaShown,
	setIsLoadingScreenShown,
	setLogin,
	setPrompt,
} from "@/redux/reducers/app";
import { DialogInfo, PromptInfo, WindowExt } from "@/types";
import { t } from "i18next";
import { JSX } from "react";
import { renderToString } from "react-dom/server";

type Dispatch = (action: { type: string; payload?: unknown }) => void;

const windowExt: WindowExt = window;

export function addResendBtn(parent: HTMLElement, callback: () => void): void {
	const newResendA = document.createElement("a");
	let remainingSeconds = 60;

	const handleClick = (): void => {
		if (remainingSeconds <= 0) {
			callback();
		}
	};

	newResendA.onclick = handleClick;
	newResendA.onkeydown = handleKeyboardClick(handleClick);
	parent.appendChild(newResendA);
	const intervalId = setInterval(() => {
		if (remainingSeconds > 0 && newResendA.parentElement) {
			remainingSeconds--;
			newResendA.textContent = t("resendAfter", {
				seconds: remainingSeconds,
			});
		} else {
			clearInterval(intervalId);
			newResendA.textContent = t("resend");
		}
	}, 1000);
}

export async function changeEmail(
	dispatch: Dispatch,
	defaultEmail = "",
): Promise<void> {
	const login = globals.login;

	const newEmail = await showPrompt(dispatch, t("enterNewEmailAddress"), {
		defaultText: defaultEmail,
		type: "email",
	});
	if (newEmail === login.email) {
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(dispatch, t("oldAndNewEmailAddressesSame"));
		return;
	}
	if (!globals.emailPattern.test(newEmail)) {
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(dispatch, t("incorrectEmailAddressFormat"));
		return;
	}
	await sleep(globals.ANIMATION_WAIT_TIME);
	await showDialog(
		dispatch,
		t("confirmEmailAddress") + newEmail,
		{
			showCancel: true,
		},
		{
			cancel: async () => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void changeEmail(dispatch, newEmail);
			},
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(dispatch, t("enterThePassword"), {
		type: "password",
	});

	const resend = async (email: string): Promise<void> => {
		const captchaResponse = await showCaptcha(dispatch);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const updateEmailResult = await api.updateEmail({
				captchaResponse: captchaResponse,
				newEmail: email,
				password: password,
			});
			dispatch(setIsLoadingScreenShown(false));
			if (updateEmailResult.alert) {
				await showDialog(dispatch, updateEmailResult.alert, {
					isHtml: true,
				});
			}
			if (!updateEmailResult.success) {
				return;
			}
			const code = await showPrompt(
				dispatch,
				t("enterCode", {
					recipient: email,
				}),
				{
					countdown: 60,
					countdownText: "resendAfter",
					inlineActionName: t("resend"),
				},
				() => {
					void resend(email);
				},
			);
			if (code.length !== 6) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(dispatch, t("verificationFailed"));
				return;
			}
			dispatch(setIsLoadingScreenShown(true));
			const verifyEmailResult = await api.verifyEmail(code);
			dispatch(setIsLoadingScreenShown(false));
			if (verifyEmailResult.alert) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(dispatch, verifyEmailResult.alert);
			}
			if (!verifyEmailResult.success) {
				return;
			}
			globals.login.email = email;
			dispatch(setLogin(globals.login));
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	void resend(newEmail);
}

export async function changePhone(
	dispatch: Dispatch,
	defaultPhoneNumber = "",
): Promise<void> {
	const login = globals.login;

	const newPhoneNumber = await showPrompt(
		dispatch,
		t("enterNewPhoneNumber"),
		{
			defaultText: defaultPhoneNumber,
			type: "tel",
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	const formattedNewPhoneNumber = formatPhoneNumber(newPhoneNumber);
	if (!formattedNewPhoneNumber) {
		void showDialog(dispatch, t("incorrectPhoneNumberFormat"));
		return;
	}
	await showDialog(
		dispatch,
		t("confirmPhoneNumber") + formattedNewPhoneNumber,
		{
			showCancel: true,
		},
		{
			cancel: async () => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void changePhone(dispatch, formattedNewPhoneNumber);
			},
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(dispatch, t("enterThePassword"), {
		type: "password",
	});

	const resend = async (phone: string): Promise<void> => {
		const emailOrPhone = login.email || login.phone;
		if (!emailOrPhone) {
			return;
		}
		const captchaResponse = await showCaptcha(dispatch);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const setPhoneResult = await api.setPhone({
				captchaResponse: captchaResponse,
				emailOrPhone: emailOrPhone,
				newPhone: phone,
				password: password,
			});
			dispatch(setIsLoadingScreenShown(false));
			if (setPhoneResult.alert) {
				await showDialog(dispatch, setPhoneResult.alert);
			}
			if (!setPhoneResult.success) {
				return;
			}
			const code = await showPrompt(
				dispatch,
				t("enterCode", {
					recipient: phone,
				}),
				{
					countdown: 60,
					countdownText: "resendAfter",
					inlineActionName: t("resend"),
				},
				() => {
					void resend(phone);
				},
			);
			if (code.length !== 6) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(dispatch, t("verificationFailed"));
				return;
			}
			dispatch(setIsLoadingScreenShown(true));
			const verifyPhoneResult = await api.verifyPhone(code);
			dispatch(setIsLoadingScreenShown(false));
			if (verifyPhoneResult.alert) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(dispatch, verifyPhoneResult.alert);
			}
			if (!verifyPhoneResult.success) {
				return;
			}
			globals.login.phone = phone;
			dispatch(setLogin(globals.login));
			if (document.referrer && globals.params.action === "changePhone") {
				window.location.href = document.referrer;
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	void resend(formattedNewPhoneNumber);
}

export async function deleteAccount(dispatch: Dispatch): Promise<void> {
	await showDialog(dispatch, t("willDeleteAllData"), {
		countdown: 5,
		showCancel: true,
	});
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(dispatch, t("enterThePassword"), {
		type: "password",
	});
	await sleep(globals.ANIMATION_WAIT_TIME * 2);
	const expectedConfirmation =
		globals.login.email ||
		globals.login.phone?.slice(-4) ||
		globals.login.username;
	const confirmation = await showPrompt(
		dispatch,
		t("enterToConfirmDeletion", {
			value: expectedConfirmation,
		}),
	);
	if (confirmation !== expectedConfirmation) {
		void showDialog(
			dispatch,
			t("yourInputDoesNotMatch", {
				expectedInput: expectedConfirmation,
				userInput: confirmation,
			}),
			{
				title: t("error"),
			},
		);
		return;
	}
	const captchaResponse = await showCaptcha(dispatch);
	dispatch(setIsLoadingScreenShown(true));
	try {
		const data = await api.deleteAccount(password, captchaResponse);
		if (data.alert) {
			await showDialog(dispatch, data.alert);
		}
		if (!data.success) {
			return;
		}
		void logOut(dispatch);
	} catch (error) {
		api.handleApiError(dispatch, error);
	} finally {
		dispatch(setIsLoadingScreenShown(false));
	}
}

export function encodeData(
	data: Record<string, string | null | undefined>,
): string {
	const array: string[] = [];
	for (const key in data) {
		const value = data[key];
		if (!value) {
			continue;
		}
		array.push(key + "=" + encodeURIComponent(value));
	}
	return array.join("&");
}

export function formatEmail(email: string): string {
	return email
		.toLowerCase()
		.replace(/\s/g, "")
		.replace(/\.con$/, ".com");
}

export function formatPhoneNumber(phoneNumber: string): string {
	const rmPrefix = (prefixes: string[]): void => {
		for (const prefix of prefixes) {
			if (phoneNumber.startsWith(prefix)) {
				phoneNumber = phoneNumber.substring(prefix.length);
			}
		}
	};

	rmPrefix(["+86", "0086"]);
	const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");
	if (
		formattedPhoneNumber.length !== 11 ||
		phoneNumber.startsWith("+") ||
		phoneNumber.startsWith("00")
	) {
		return "";
	}
	return formattedPhoneNumber;
}

export function handleKeyboardClick(
	onClick: () => void,
): (event: KeyboardEvent | React.KeyboardEvent<Element>) => void {
	return (event: KeyboardEvent | React.KeyboardEvent<Element>): void => {
		switch (event.key) {
			case "Enter":
			case " ": {
				onClick();
				event.preventDefault();
				break;
			}
			default:
				break;
		}
	};
}

export function loadJs(src: string): Promise<void> {
	return new Promise((resolve, reject): void => {
		if (globals.loadedJs.has(src)) {
			resolve();
			return;
		}
		globals.loadedJs.add(src);
		setTimeout((): void => {
			const newScript = document.createElement("script");
			newScript.src = src;
			newScript.onerror = reject;
			newScript.onload = (): void => {
				resolve();
			};
			document.body.appendChild(newScript);
		}, 1);
	});
}

export async function logOut(dispatch: Dispatch): Promise<void> {
	dispatch(setIsLoadingScreenShown(true));
	try {
		await api.logOut();
		localStorage.clear();
		window.location.reload();
	} catch (error) {
		api.handleApiError(dispatch, error);
	} finally {
		dispatch(setIsLoadingScreenShown(false));
	}
}

export function showCaptcha(dispatch: Dispatch): Promise<CaptchaResponse> {
	return new Promise<CaptchaResponse>((resolve) => {
		if (navigator.userAgent.includes("Cypress")) {
			resolve({
				ticket: "test-ticket",
			});
			return;
		}
		if (windowExt.TencentCaptcha) {
			const captcha = new windowExt.TencentCaptcha(
				"2083026945",
				(captchaResponse: CaptchaResponse) => {
					if (captchaResponse.ret !== 0 || !captchaResponse.ticket) {
						return;
					}
					resolve(captchaResponse);
				},
			);
			captcha.show();
		} else {
			globals.captchaCallback = resolve;
			dispatch(setIsCaptchaShown(true));
		}
	});
}

export function showDialog(
	dispatch: Dispatch,
	text: string | JSX.Element,
	options: DialogInfo = {},
	callbacks?: typeof globals.dialogCallbacks,
): Promise<void> {
	return new Promise<void>((resolve) => {
		if (callbacks) {
			globals.dialogCallbacks = callbacks;
		}
		if (!globals.dialogCallbacks.ok) {
			globals.dialogCallbacks.ok = (): void => {
				resolve();
			};
		}
		if (typeof text === "string") {
			options.text = text;
		} else {
			options.text = renderToString(text);
		}
		dispatch(setDialog(options));
	});
}

export function showPrompt(
	dispatch: Dispatch,
	text: string,
	options: PromptInfo = {},
	inlineAction: typeof globals.promptInlineAction = null,
): Promise<string> {
	return new Promise<string>((resolve) => {
		globals.promptCallback = resolve;
		globals.promptInlineAction = inlineAction;
		options.text = text;
		dispatch(setPrompt(options));
	});
}

export function sleep(delay: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}
